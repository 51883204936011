export const TOKEN = {
    BOUVET: 'BOUVET_TOKEN',
}
export const API_URL = process.env.VUE_APP_API_URL;
export const BASE_FILE_URL = process.env.VUE_APP_BASE_FILE_URL;


export const CREW_DOCUMENTS_FILE_FORMATS = {
    IMAGE: ["image/jpeg", "image/jpg", "image/png"],
    FILE: ["application/pdf"]
}

export const FIELD_STATUS_COLORS = {
    0: "",
    1: "is-valid",
    2: "is-invalid"
}
export const EXCLUDE_ROUTE_OPTIONS = ["Dashboard", "FailOverPage", "PasswordExpiration", "LogIn", "UnAuthorized", "userRestriction", "Home", "UserProfile"];

export const ERROR_FIELD = 2;
export const INITIAL_FIELD = 0;
export const SUCCESS_FIELD = 1;

export const CREW_IMAGE = {
    FILE_FORMATS: ['image/jpeg', 'image/png']
}
export const error_required_fields = 'This field is required';

export const BIRTHYEAR_MINIMUM = 1900;

export const IDLE_LIMIT = 3600;
export const IDLE_WARNING = 30;

export const STATUS = [{code: 1, value: 'Active'}, {code: 0, value: 'Inactive'}];
export const ALLOWED_GEN_OPT = ['endorser_type', 'engine_type', 'vessel_size', 'curriculum', 'program', 'selection_type', "vessel_type", "trade_route", "union", "classification_code", "salary_columns", "engine_model"];
export const ALLOWED_CREW_CHANGE = ['crew-change-plan', 'crew-change-manipulation'];

export const INPUT_TYPES = [
    'button',
    // 'checkbox',
    'color',
    'date',
    'datetime-local',
    'email',
    'file',
    'hidden',
    'image',
    'month',
    'year',
    'number',
    'password',
    'radio',
    'range',
    'reset',
    'search',
    'submit',
    'tel',
    'text',
    'time',
    'url',
    'week'
];
