// router/index.js
import {createRouter, createWebHistory} from 'vue-router';
import {ALLOWED_CREW_CHANGE, ALLOWED_GEN_OPT, EXCLUDE_ROUTE_OPTIONS, TOKEN} from "@/config/constants.config";
import {DateTime, Duration} from "luxon";

export const routes = [
    {
        path: '/',
        name: 'LogIn',
        component: () => import('@/views/LogIn.vue'),
    },

    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        redirect: '/dashboard',
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'unauthorized',
                name: 'UnAuthorized',
                component: () => import('@/components/base/FailOverPage.vue'),
            },
            {
                path: "renew-password",
                name: "PasswordExpiration",
                component: () => import("@/views/RenewPassword.vue"),
            },
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: () => import('@/components/modules/dashboard/Dashboard.vue'),
            },
            {
                path: 'crew',
                name: 'CrewMain',
                component: () => import('@/views/Crews.vue'),
                redirect: 'crew/list',
                children: [
                    {
                        path: 'list',
                        name: 'CrewList',
                        component: () => import('@/components/modules/crew/List.vue'),
                    },
                    {
                        path: 'create',
                        name: 'CreateCrew',
                        component: () => import('@/components/modules/crew/_sub/CreateCrew.vue'),
                    },

                ]
            },
            {
                path: 'applicant',
                name: 'Applicants',
                component: () => import('@/components/modules/applicants/ApplicantMain.vue'),
            },
            {
                path: 'profile/:crewId',
                name: 'CrewProfile',
                component: () => import('@/components/modules/crewProfile/CrewProfile.vue'),
                props: (route) => ({
                    crewId: Number(route.params.crewId),
                }),
                redirect: {name: 'AllInfo'},
                children: [
                    {
                        path: 'all-info',
                        name: 'AllInfo',
                        component: () => import('@/components/modules/crewProfile/_sub/allinfo/AllInfo.vue'),
                        redirect: {name: 'CrewPersonal'}, // Redirect to 'CrewPersonal' by name
                        children: [
                            {
                                path: 'personal',
                                name: 'CrewPersonal',
                                component: () => import('@/components/modules/crewProfile/_sub/allinfo/_sub/CrewPersonal.vue'),
                                props: true,
                            },
                            {
                                path: 'contact',
                                name: 'CrewAddressAndContact',
                                component: () => import('@/components/modules/crewProfile/_sub/allinfo/_sub/CrewAddressAndContact.vue'),
                                props: true,
                                children: [
                                    //TODO
                                ],
                            },
                            {
                                path: 'education',
                                name: 'CrewEducation',
                                component: () => import('@/components/modules/crewProfile/_sub/allinfo/_sub/CrewEducation.vue'),
                                props: true,
                            },
                            {
                                path: 'family',
                                name: 'CrewFamily',
                                component: () => import('@/components/modules/crewProfile/_sub/allinfo/_sub/CrewFamily.vue'),
                                props: true,
                            },
                            {
                                path: 'gears',
                                name: 'CrewGears',
                                component: () => import('@/components/modules/crewProfile/_sub/allinfo/_sub/CrewGears.vue'),
                                props: true,
                            },
                            {
                                path: 'scholarship',
                                name: 'CrewScholarship',
                                component: () => import('@/components/modules/crewProfile/_sub/allinfo/_sub/CrewScholarship.vue'),
                                props: true,
                            },
                            {
                                path: 'application',
                                name: 'CrewApplication',
                                component: () => import('@/components/modules/crewProfile/_sub/allinfo/_sub/CrewApplication.vue'),
                                props: true,
                            },
                            {
                                path: 'referral',
                                name: 'CrewReferral',
                                component: () => import('@/components/modules/crewProfile/_sub/allinfo/_sub/CrewReferral.vue'),
                                props: true,
                            },
                        ],
                    },
                    {
                        path: 'sea-service',
                        name: 'CrewSeaService',
                        component: () => import('@/components/modules/crewProfile/_sub/sea-service/SeaService.vue'),
                    },
                    {
                        path: 'crew-document/:documentCategoryId',
                        name: 'CrewDocument',
                        component: () => import('@/components/modules/crewProfile/_sub/document/CrewDocumentMain.vue'),
                        props: route => ({documentCategoryId: Number(route.params.documentCategoryId)}),
                    },
                    {
                        path: 'crew-medical',
                        name: 'CrewMedical',
                        component: () => import('@/components/modules/crewProfile/_sub/medical/_sub/CrewMedical.vue'),
                    },
                    {
                        path: 'crew-incidents',
                        name: 'CrewIncidents',
                        component: () => import('@/components/modules/crewProfile/_sub/medical/_sub/CrewIncidents.vue'),
                    },
                    {
                        path: 'direct-promotion',
                        name: 'DirectPromotion',
                        component: () => import('@/components/modules/crewProfile/_sub/direct-promotion/DirectPromotionLogs.vue'),
                    },
                    {
                        path: 'crew-scanned-checklist',
                        name: 'CrewScannedChecklist',
                        component: () => import('@/components/modules/crewProfile/_sub/scanned-checklist/ScannedChecklist.vue'),
                    },
                    {
                        path: 'crew-comments',
                        name: 'CrewComment',
                        component: () => import('@/components/modules/crewProfile/_sub/comment/CrewComment.vue'),
                    },
                    {
                        path: 'crew-evaluation',
                        name: 'CrewEvaluation',
                        component: () => import('@/components/modules/crewProfile/_sub/evaluation/CrewEvaluation.vue'),
                        props: true
                    },
                    {
                        path: 'crew-withdrawals',
                        name: 'Withdrawal',
                        component: () => import('@/components/modules/crewProfile/_sub/withdrawal/Withdrawal.vue'),
                        props: true
                    },
                    {
                        path: 'outside-sea-service',
                        name: 'CrewOutsideSeaService',
                        component: () => import('@/components/modules/crewProfile/_sub/outside-service/OutsideSeaService.vue'),
                    },

                ],
            },
            {
                path: "setup",
                name: "SetupPage",
                redirect: 'ranks',
                component: () => import("@/views/SetupPage.vue"),
                children: [
                    {
                        path: 'list/:type',
                        name: 'GeneralOptions',
                        component: () => import('@/components/modules/setup/GeneralOptions.vue'),
                        props: true,
                        beforeEnter: (to, from, next) => {
                            const type = to.params.type;
                            if (!ALLOWED_GEN_OPT.includes(type)) {
                                next({name: 'FailOverPage'});
                            } else {
                                next();
                            }
                        }
                    },
                    {
                        path: 'ranks',
                        name: 'RankLists',
                        component: () => import("@/components/modules/setup/RankLists.vue"),
                    },
                    {
                        path: 'documents',
                        name: 'DocumentLists',
                        component: () => import("@/components/modules/setup/DocumentLists.vue"),
                    },
                    {
                        path: 'schools',
                        name: 'SchoolList',
                        component: () => import("@/components/modules/setup/SchoolList.vue"),
                    },
                    {
                        path: 'scholarship-setup',
                        name: 'ScholarshipSetup',
                        children: [
                            {
                                path: 'endorser',
                                name: 'EndorserSetup',
                                children: [
                                    {
                                        path: 'list',
                                        name: 'EndorserLists',
                                        component: () => import('@/components/modules/setup/scholarship/EndorserLists.vue'),
                                    },
                                ]
                            },
                            {
                                path: 'batch',
                                name: 'BatchLists',
                                component: () => import("@/components/modules/setup/scholarship/BatchLists.vue"),
                            },

                        ]
                    },
                    {
                        path: 'education-degree',
                        name: 'EducationDegree',
                        component: () => import("@/components/modules/setup/EducationDegree.vue"),
                    },
                    {
                        path: 'clinics',
                        name: 'ClinicList',
                        component: () => import("@/components/modules/setup/ClinicList.vue"),
                    },
                    {
                        path: "medical-issues",
                        name: "MedicalIssuesList",
                        component: () => import("@/components/modules/setup/MedicalIssuesList.vue")
                    },
                    {
                        path: "crew-incident-reasons",
                        name: "CrewIncidentReasonList",
                        component: () => import("@/components/modules/setup/CrewIncidentReasonList.vue")
                    },
                    {
                        path: 'principals',
                        name: 'PrincipalList',
                        component: () => import("@/components/modules/setup/PrincipalList.vue"),
                    },
                    {
                        path: 'owners',
                        name: 'OwnerList',
                        component: () => import("@/components/modules/setup/OwnerList.vue"),
                    },
                    {
                        path: 'ports',
                        name: 'PortList',
                        component: () => import("@/views/setup/PortList.vue"),
                    },
                    {
                        path: "vessel_setup",
                        name: "VesselSetup",
                        redirect: "vessels",
                        children: [
                            {
                                path: "vessels",
                                name: "VesselList",
                                component: () => import("@/views/setup/VesselList.vue")
                            },
                            {
                                path: "engine_types",
                                name: "EngineTypeList",
                                component: () => import("@/components/modules/setup/EngineTypeList.vue")

                            }
                        ]
                    },
                    {
                        path: 'flags',
                        name: 'FlagList',
                        component: () => import("@/components/modules/setup/FlagList.vue"),
                    },
                    {
                        path: 'fleet',
                        name: 'FleetList',
                        component: () => import("@/views/setup/FleetList.vue"),
                    },
                    {
                        path: 'positions',
                        name: 'PositionList',
                        component: () => import("@/components/modules/setup/PositionLists.vue"),
                    },
                    {
                        path: 'departments',
                        name: 'UserDepartments',
                        component: () => import("@/components/modules/setup/UserDepartments.vue"),
                    },
                    {
                        path: 'signatories',
                        name: 'SignatoryLists',
                        component: () => import("@/components/modules/setup/SignatoryLists.vue"),
                    },
                    //Salary matrix
                    {
                        path: "salary-matrix",
                        name: "SalaryMatrixSetup",
                        children: [
                            {
                                path: "vessels-salary-column",
                                name: "VesselSalaryColumn",
                                component: () => import("@/components/modules/setup/SalaryMatrix/VesselSalaryColumn.vue"),
                            },
                            {
                                path: "vessel-salary-list",
                                name: "VesselSalaryList",
                                component: () => import("@/components/modules/setup/SalaryMatrix/VesselSalaryList.vue"),
                            },
                            {
                                path: "cba-list",
                                name: "CBAList",
                                component: () => import("@/components/modules/setup/SalaryMatrix/CBAList.vue"),
                            }
                        ]
                    },
                    {
                        path: 'contract',
                        name: 'ContractList',
                        component: () => import("@/components/modules/setup/ContractList.vue"),
                    },
                    // {
                    //     path: 'evaluation-form',
                    //     name: 'EvaluationForm',
                    //     component: () => import("@/components/modules/setup/EvaluationForm.vue"),
                    // },
                    //User
                    {
                        path: 'users',
                        name: 'UserSetup',
                        children: [
                            {
                                path: "list",
                                name: "userList",
                                component: () => import("@/components/modules/setup/users/userList.vue"),
                            },
                            {
                                path: "restriction",
                                name: "userRestriction",
                                component: () => import("@/components/modules/setup/users/userRestriction.vue"),
                            },
                        ]
                    },
                ]
            },
            {
                path: 'crewing',
                name: 'Crewing',
                component: () => import("@/views/Crewing.vue"),
                children: [
                    {
                        path: 'crew-change/:type',
                        name: 'CrewChange',
                        component: () => import("@/components/modules/crewing/crewChange/CrewChangeMain.vue"),
                        props: true,
                        beforeEnter: (to, from, next) => {
                            const type = to.params.type;
                            if (!ALLOWED_CREW_CHANGE.includes(type)) {
                                next({name: 'FailOverPage'});
                            } else {
                                next();
                            }
                        }
                    },
                    {
                        path: 'crew-change-overview',
                        name: 'CrewChangeOverview',
                        component: () => import("@/components/modules/crewing/crewChange/Overview/CCPOMain.vue"),
                        props: true,
                    },
                    {
                        path: 'document-checklist',
                        name: 'DocumentChecklist',
                        component: () => import("@/components/modules/crewing/documentChecklist/DocumentChecklist.vue"),
                        props: true,
                    },
                    {
                        path: 'contract-generation',
                        name: 'ContractGeneration',
                        component: () => import("@/components/modules/crewing/ContractGeneration/Main.vue"),
                        props: true,
                    },
                ],
            },
            {
                path: 'user-profile',
                name: 'UserProfile',
                component: () => import("@/components/modules/setup/UserProfile.vue"),
            },
            {
                path: 'report',
                name: 'ReportPage',
                component: () => import("@/views/ReportPage.vue"),
                children: [
                    {
                        path: 'list/:type',
                        name: 'Report',
                        component: () => import('@/components/modules/Report/Main.vue'),
                        props: true,
                    },
                ]
            },
            {
                path: '/:pathMatch(.*)*',
                name: 'FailOverPage',
                component: () => import('@/components/base/FailOverPage.vue'),
                meta: {requiresAuth: false},
            }
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

function hasQueryParams(route) {
    return !!Object.keys(route.query).length;
}

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem(TOKEN.BOUVET);
    const authUser = token ? JSON.parse(localStorage.getItem('authUser')) : null;
    if (to.meta.requiresAuth) {
        if (authUser) {
            const passwordExpiration = DateTime.fromSQL(authUser.password_expiration ?? DateTime.now().plus(Duration.fromObject({months: 6})));
            if (passwordExpiration <= DateTime.now() && to.name !== "PasswordExpiration") {
                next({name: "PasswordExpiration"});
                return;
            }
            const hasAccess = authUser.routes?.includes(to.name);
            if (!hasAccess && authUser.routes?.length > 0 && !EXCLUDE_ROUTE_OPTIONS.includes(to.name) && authUser.department !== "IT") {
                return next({name: "UnAuthorized"});
            }

            if (!hasQueryParams(to) && hasQueryParams(from)) {
                next({name: to.name, query: from.query});
            } else {
                next();
            }
        } else {
            next({name: 'LogIn'});
        }
    } else {
        if (!authUser) {
            next();
        } else {
            next({name: 'Home'});
        }
    }
});

export default router;
