<template>
  <div class="card border-0 mt-0 h-auto">
    <div class="card-body px-1 m-0 border-0">
      <div class="table-responsive custom-table-responsive" :style="{ maxHeight: maxHeight ? maxHeight + 'px' : 'auto', width: maxWidth + '%' }">
        <table class="table table-bordered table-sm text-center small table-hover"
                :class="headerClass">
          <thead>
          <slot name="table-header"></slot>
          </thead>
          <tbody>
          <tr v-if="loading">
            <td :colspan="loaderColspan">
              <div class="d-flex justify-content-center align-items-center">
                <span class="d-block mr-2">{{ loaderText }}</span>
                <div class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
              </div>
            </td>
          </tr>
          <slot name="table-body" v-else></slot>
          </tbody>
          <tfoot>
          <slot name="table-footer"></slot>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue';

export default {
  name: 'TableLayout',
  props: {
    maxHeight: {
      type: Number,
      default: 500,
    },
    maxWidth: {
      type: Number,
      default: 100,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loaderColspan: {
      type: Number,
      default: 1,
    },
    loaderText: {
      type: String,
      default: 'Loading . . .',
    },
    headerClass: {
      default:'sticky-header',
    }
  },

  setup() {
    onMounted(() => {
      const tables = document.querySelectorAll('table');
      tables.forEach((table) => {
        const tfoot = table.querySelector('tfoot');
        if (tfoot) {
          table.insertBefore(tfoot, table.querySelector('thead').nextSibling);
        }
      });
    });

    return {};
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 8px !important;
}
.table-responsive {
  min-height: 300px;
}

</style>
